import React from 'react';

import './App.css';
import CrystallineRift from './CrystallineRift';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <CrystallineRift />
  );
}

export default App;
