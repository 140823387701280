import { FunctionComponent } from "react";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/esm/Image";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import styles from './CrystallineRift.module.css';
import hellcat from './img/15 - Hellcat & Grass.png';
import sky from './img/1 - Sky 1.png'
import flyers from './img/2 - Distant Flying Criederes.png';
import pillar from './img/3 - Pillar.png';
import clouds from './img/4 - Clouds 1.png';
import backmountains from './img/5 - Back Mountains.png';
import clouds2 from './img/6 - Clouds 2.png';
import mountains from './img/7 - Middle Mountains.png';
import clouds3 from './img/8 - Clouds 3.png';
import horizonHill from './img/9 - Horizon Hill Green.png';
import grassyGround from './img/10 - Grassy Ground.png';
import camelDrake from './img/11 - Camel Drake Family 1.png';
import smallFlyers from './img/12 - Flying Criederes Small.png';
import foregroundCliff from './img/13 - Foreground Cliff.png';
import soulstone from './img/14 - Figure & Soulstone.png';
import discord from './img/discord.svg';
import steam from './img/steam.svg';
import SubscribeComponent from "./SubscribeComponent";

const CrystallineRift: FunctionComponent = () => {
    const navigateToDiscord = () => {
        try{
            window.location.href = "discord://discordapp.com/channels/1080935105012432948/1080935105511563458";
        }catch(error){
            window.location.href = "https://discord.com/channels/1080935105012432948/1080935105511563458";
        }        
    }
    return (
        <div className={styles.fullSize + ' ' + styles.bgimg}>
            <div className={styles.fullSize + ' ' + styles.background}>
                <Container>
                    <Row className={styles.counterspellGamesText}><span>//</span></Row>
                    <Row xs={2} md={2} lg={6} className={styles.titleWrapper}>
                        <Col className={styles.crystallineText}>Crystalline</Col>
                        <Col className={styles.riftText}>Rift</Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col className={styles.comingSoonText}>Coming to Kickstarter in 2025.</Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col><SubscribeComponent /></Col>
                    </Row>
                    <Row xs="auto">
                        <Col><Button variant="outline-dark" size="lg" className={styles.socialButton} onClick={navigateToDiscord}><Image src={discord} rounded /> Join Our Discord</Button></Col>
                        <Col><Button variant="outline-dark" size="lg" className={styles.socialButton} ><Image src={steam} rounded /> Wishlist On Steam</Button></Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default CrystallineRift;