// src/SubscribeComponent.tsx
import styles from './SubscribeComponent.module.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

const SubscribeComponent: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [response, setResponse] = useState<string>('');
    const [csrfToken, setCsrfToken] = useState<string>('');
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        // Generate a random CSRF token
        const token = Math.random().toString(36).substring(2);
        setCsrfToken(token);
    }, []);

    const registeredEmail = () => localStorage.getItem('cr-email-signup');
    const hasRegisteredEmail = () => localStorage.getItem('cr-email-signup') == null;

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let validEmail = emailRegex.test(email);
        event.preventDefault();
        if (form.checkValidity() === false || !validEmail) {
            event.stopPropagation();
            form.isInvalid = true;
            form.isValid = false;
        }
        // Basic email validation
        setValidated(!validEmail);
        if (!validEmail) return;
        try {
            const res = await axios.post('https://script.google.com/macros/s/AKfycbzhE9ymaDeUZH-taViUJnc_a5PZ7_uWbgjz4pZXlnzBSrPDCc8OmGs5SVBDAA4wSBz_9A/exec',
                {
                    email: email,
                    token: '7068b83e-baf8-4ee2-896c-73a469fbdfba',
                    csrfToken: csrfToken,
                },
                {
                    headers: {
                        'Content-Type': 'text/plain'
                    }
                });
            localStorage.setItem('cr-email-signup', email);
            setResponse('Thank you for subscribing!');
        } catch (error) {
            setResponse('Error submitting the form.');
        }
    };

    return (
        <>
            {hasRegisteredEmail() &&
                <>
                    <Row>
                        <Col className={styles.joinNewsletterText}>Join our newsletter to stay up in the know with game developers!</Col>
                    </Row>
                    <Form validated={validated} onSubmit={handleSubmit}>
                        <input type="hidden" value={csrfToken} />
                        <Row className="mb-3">
                            <Form.Group className="mb-3" as={Col} md="9" controlId="validationCustom01">
                                <InputGroup hasValidation className={styles.emailInput}>
                                    <Form.Control
                                        size="lg"
                                        type="email"
                                        placeholder="Enter your email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" as={Col} md="3">
                                <Button type="submit" variant="dark" size="lg">Subscribe</Button>
                            </Form.Group>
                        </Row>
                        <Row>
                            {response}
                        </Row>
                    </Form>
                </>
            }
            {!hasRegisteredEmail() &&
                <Row>
                    <Col className={styles.joinNewsletterText}>{"Thanks for signing up, " + registeredEmail() + "! "}</Col>
                </Row>
            }
        </>
    );
};

export default SubscribeComponent;
